 @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$verde : #ffffff;
$azul : #2DAAE1;
$verde_marinho: #00BCA2;
$texto: #333333;
$fundo_verde:#026E5E;
$escuro:#31A08F;

section{
	padding-top:90px;
	padding-bottom: 100px;
}

a{
	color:$texto;
}

a:hover{
	color:$verde_marinho;
	text-decoration:none;
}

#banner{
	padding-top:0;
	padding-bottom:0;
}

#planos{
	background-color:#fff;
}

#palestras{
	padding-bottom:0;
}

#contato{
	padding-bottom:50px;
}

#agenda{
	padding-top:180px;
	padding-bottom:180px;;
}

#parceiros{
	padding-top:20px;
}

.breadcrumb{
	margin-top:10px;
}

.azul{
	color:$azul;
}

.escuro{
	color:$escuro;
}

.navbar{
	max-height:90px;
	background-color: $verde;
	border-color:#AAACAC;
}

.logo{
	//padding:2px;
	margin-bottom:15px;
}

#banner{
	margin-top:80px;
}

.navbar-default .navbar-nav > li > a {
	color:$texto;
	font-size: 16px;
	padding-top:30px;
}

.navbar-default .navbar-nav > li > a:hover {
	color:$verde_marinho;
}

.botao{
	background-color:$azul;
	border-color:$azul;
}

.bt{
	width:100%;
	margin-bottom:10px;
}

.botao:hover{
	background-color:$verde_marinho;
	border-color:$verde_marinho;
}

.rodape{
	min-height:20px;
	background-color:#026E5E;
	color:#fff;
}

.sociais{
	margin-top:-5px;
}

.palestras a{
	color:$texto;
}

.palestras a:hover{
	color:$verde_marinho;
	text-decoration:none;
}

.palestras{
	text-align:center;
	margin-bottom:50px;
}

.menu{
	margin-top:5px;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus{
	padding-bottom:25px;
}

.agenda{
	background-color:$fundo_verde;
}

.agendar{
	width:60%;
	background-color:transparent;
	border-color:#fff;
	color:#fff;
	margin-left:50px;
}

.white{
	color:#fff;
}

.text-empresa{
	text-align:justify;
}

.space{
	padding-left:30px;
}

.m-active{
	background-color: #e7e7e7;
    color: #555;
    padding-bottom: 10px;
}

.innovare{
	color:#fff;
}
